import React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import { rhythm } from "../utils/typography"
import ItemTile from "../components/item-tile"
import parse from "html-react-parser"

const ListPageLayout = ({ location, list }) => {
  const webLink = `https://tictic.app/list/${list.listId}`
  const fdLink = `https://tictic.page.link/?link=${webLink}&apn=co.tictic.tictic&isi=1437805697&ibi=co.tictic.tictic`
  // `https://your_subdomain.page.link/?link=your_deep_link&apn=package_name[&amv=minimum_version][&afl=fallback_link]`;

  return (
    <Layout title={list.title} location={location}>
      <Seo title={list.title} description={list.description} />
      <hr
        style={{
          marginTop: rhythm(1),
          marginBottom: rhythm(1),
        }}
      />
      <article>
        <header style={{ flexFlow: `column`, textAlign: `center` }}>
          <h3
            style={{
              marginTop: rhythm(1),
              marginBottom: rhythm(1),
            }}
          >
            {list.title}
          </h3>
          <img
            src={
              list.image?.dynamicUrl
                ?.replace("[WIDTH]", 400)
                ?.replace("[HEIGHT]", 265) || ""
            }
            alt={list.title}
            style={{
              borderRadius: `3%`,
              maxHeight: `40vh`,
            }}
          />

          <p>{parse(list.description ?? "")}</p>
        </header>
      </article>
      <hr
        style={{
          marginTop: rhythm(1),
          marginBottom: rhythm(2),
        }}
      />
      <ul className="grid-layout">
        {list.items.map((value, index) => {
          return <ItemTile value={value} index={index} key={index}></ItemTile>
        })}
      </ul>
      <a href={fdLink}>Open in App</a>
    </Layout>
  )
}

export default ListPageLayout
