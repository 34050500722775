import React from 'react';
import { Router } from "@reach/router";

import ListComponent from "../components/list-component"

const List = ({ location }) => {
  return (
    <>
      <Router basepath="/lists">
        <ListComponent path="/:listId" location={location} />
      </Router>
    </>
  )
}

export default List