import React from "react"
import gql from 'graphql-tag';
import { useQuery } from '@apollo/react-hooks';
// import { rhythm } from "../utils/typography"
// import Thumbnail from "../components/thumbnail"
// import icon from "../images/icon_large_1024.jpg"
import ListPageLayout from './list-page-layout'

const APOLLO_QUERY = gql`
  query($listId: ID!) {
    getList(listId: $listId) {
      listId
      title
      description
      image {
        dynamicUrl
      }
      items{
        itemId
        name
        cardImage {
          thumbUrl
          dynamicUrl
        }
      }
    }
  }
`

const ListComponent = (prop) => {
  const { data } = useQuery(APOLLO_QUERY, { variables: { listId: prop.listId } });

  if (data && data.getList) {
    const list = data.getList
    return (<ListPageLayout list={list} title={prop.title} location={prop.location} />);
  } else {
    return (<div>...</div>);
  }
}

export default ListComponent